import * as React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import TopBanner from '../components/Banners/TopBanner/TopBanner'
import Breadcrumb from '../components/organisms/breadcrumb/Breadcrumb'
import Content from '../components/Content/Content'
import PageMeta from '../components/PageMeta/PageMeta'
import { type IAwardsQueryData } from '../graphql/queries/AwardsQuery'
import AwardsMenu from '../components/MuddyAwards/AwardsMenu'
import Leaderboard from '../components/Banners/Leaderboard/Leaderboard'
import AwardsHeader from '../components/MuddyAwards/AwardsHeader'

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        content
        uri
        parent {
          node {
            uri
          }
        }
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

type AwardsContentPage = {
  data: IAwardsQueryData
}

const AwardsContentPage = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      page,
      category
    }
  }
}: AwardsContentPage) => {
  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image: 'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={page.title ?? ''}
            postURI={page.uri ?? ''}
          />
          <h1 dangerouslySetInnerHTML={{ __html: page.title ?? '' }} />
          <hr />
          <span dangerouslySetInnerHTML={{ __html: page.content ?? '' }} />
        </Content>
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsContentPage
